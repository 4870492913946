import { Header, Table as ReactTable, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { ReactNode, useState } from "react"
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { DebouncedInput } from "./Form"
import { useKey } from 'react-use'

export const Table = ({ children }: { children: ReactNode }) => {
    return (
        <table className="react-table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            {children}
        </table>
    )
}

export const TableErrorMessage = ({
    isError = false,
    message = 'システムエラーが発生しました、システム管理者へお問い合わせ下さい。',
    col,
    }: {
        isError: boolean,
        message: string | undefined,
        col: number
    }) => {
    if (!isError) return
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan={col} className="py-32 px-10 text-center">{message}</td>
        </tr>
    )
}

export const TableLoading = ({ isLoading = false }: { isLoading: boolean }) => {
    if (!isLoading) return
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan={5} className="py-32 px-10 text-center">Loading...</td>
        </tr>
    )
}

export const Thead = ({ children }: { children: ReactNode }) => {
    return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            {children}
        </thead>
    )
}

export const Tbody = ({ children }: { children: ReactNode }) => {
    return (
        <tbody>
            {children}
        </tbody>
    )
}

export const Tr = ({ children }: { children: ReactNode }) => {
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            {children}
        </tr>
    )
}

export const Th = ({ children, className = '', header }: { children: ReactNode, className?: string, header?: Header<any, unknown> }) => {
    return (
        <th 
            scope="col" 
            className={ header?.column.getCanSort() ? `px-6 py-3 cursor-pointer ${className}` : `px-6 py-3 ${className}`} 
            onClick={header?.column.getToggleSortingHandler()} 
        >
            {children}
            {{
                asc: <ExpandLessIcon sx={{ width: 16, height: 16 }} />,
                desc: <ExpandMoreIcon sx={{ width: 16, height: 16 }} />,
            }[header?.column.getIsSorted() as string] ?? null}
        </th>
    )
}

export const Td = ({ children, className, colspan }: { children: ReactNode, className?: string, colspan?:number }) => {
    return (
        <td className={`px-6 py-4 ${className}`} colSpan={colspan}>
            {children}
        </td>
    )
}

export const Pagination = ({ table, defaultPageSize }: {table: ReactTable<any>, defaultPageSize: number}) => {
    const [pageSize, setPageSize] = useState<number>(defaultPageSize)
    return (
        <>
            <div className="pagination flex items-center">
                <a
                    className={table.getCanPreviousPage() ? 'arrow' : 'arrow disabled'}
                    onClick={() => table.previousPage()}
                >
                    <KeyboardArrowLeftIcon />
                </a>

                {/* {Array.from({ length: table.getPageCount() }, (_, i) => i).map(
                    (index) => (
                        <a
                            key={index}
                            className={table.getState().pagination.pageIndex === index ? 'current' : ''}
                            onClick={() => table.setPageIndex(index)}
                        >
                            <span className="pageNumber">{index + 1}</span>
                        </a>
                    )
                )} */}

                <a 
                    className={table.getCanNextPage() ? 'arrow' : 'arrow disabled'}
                    onClick={() => {
                        if (table.getCanNextPage()) {
                            table.nextPage()
                        }
                    }}
                >
                    <KeyboardArrowRightIcon />
                </a>

                <div className="ml-4 text-sm">
                    表示件数：
                    <select
                        className='text-gray-800'
                        value={pageSize}
                        onChange={(e) => {
                            table.setPageSize(parseInt(e.target.value))
                            setPageSize(parseInt(e.target.value))
                        }}
                    >
                        <option value={3}>3</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                    件 / 全 {table.getRowCount().toLocaleString()} 件
                </div>
            </div>
        </>

    )
}

export const PaginationTable = ({ data, columns, pageSize=50, isSearchParts=true, children }: {data: any, columns: any, pageSize?: number, isSearchParts?: boolean, children?: ReactNode}) => {
    const [globalFilter, setGlobalFilter] = useState('')
    let headerCount = 0

    const table = useReactTable({
        data,
        columns,
        state: {
            globalFilter
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        initialState: {
            pagination: {
                pageSize
            },
            sorting: [
                {
                    id: columns[0].accessorKey,
                    desc: true
                }
            ]
        }
    })

    // キーボードの左右でページ移動させる
    useKey('ArrowLeft', () => {
        if (table.getCanPreviousPage()) {
            table.previousPage()
        }
    })
    useKey('ArrowRight', () => {
        if (table.getCanNextPage()) {
            table.nextPage()
        }
    })

    return (
        <>
            <div className="button_area mb-2">
                {isSearchParts ? (
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                    />
                ) : ''}
                
                {children}
            </div>

            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup, index) => (
                        <tr key={index}>
                            {headerGroup.headers.map((header, index) => {
                                headerCount++
                                return (
                                    <Th header={header} key={index}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </Th>
                                )
                            })}
                        </tr>
                    ))}
                </Thead>

                <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                        <Tr key={index}>
                            {row.getVisibleCells().map((cell, index) => (
                                <Td key={index}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                    {table.getRowModel().rows.length == 0 && (
                        <Tr>
                            <Td className="text-center h-48" colspan={headerCount}>No Data</Td>
                        </Tr>
                    )}
                </tbody>
            </Table>
            <div className='flex justify-end mt-2'>
                <Pagination table={table} defaultPageSize={pageSize} />
            </div>
        </>
    )
}
